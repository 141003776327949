import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';

// Define async thunks for login and logout
export const loginAdmin = createAsyncThunk(
  'admin/login',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/login', requestBody);

      const currentTime = Date.now();
      localStorage.setItem('sessionStartTime', currentTime.toString());
      const user = response.data.user;
      if (user.admin_city_id != user.city.id) {
        alert('You are not authorized to access this city, Please to go Mobile app and update your current city to city you are an administer');
        return rejectWithValue({ message: 'You are not authorized to access this city, Please to go Mobile app and update your current city to city you are an administer' });
      }
      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutAdmin = createAsyncThunk(
  'admin/logout',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post('/auth/logout',{}, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  user: {},
  cities: [],
  hosts: [],
  authToken: '',
  eventTypes: [],
  isAuthenticated: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState; // Reset state to initial values
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.isAuthenticated = false;
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = '';
        state.authToken = '';
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = '';
        state.user = action.payload.user;
        state.cities = action.payload.cities;
        state.hosts = action.payload.hosts;
        state.authToken = action.payload.auth_token;
        state.eventTypes = action.payload.event_types;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.cities = [];
        state.hosts = [];
        state.user = {};
        state.eventTypes = [];
        state.authToken = '';
        state.errorMessage = action.payload.message;
      })
      .addCase(logoutAdmin.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = '';
      })
      .addCase(logoutAdmin.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = '';
        state.cities = [];
        state.hosts = [];
        state.eventTypes = [];
        state.user = {};
        state.authToken = '';
      })
      .addCase(logoutAdmin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload.message;
      })
  },
});

// Action creators are generated for each case reducer function
export const { clearState } = adminSlice.actions;

export default adminSlice.reducer;